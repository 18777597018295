// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (100 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  jQuery(".sp-btn").on("click", function() {
    jQuery(this)
      .next()
      .slideToggle();
  });

  /* 電話リンク */
  let ua = navigator.userAgent;
  if (ua.indexOf("iPhone") < 0 && ua.indexOf("Android") < 0) {
    jQuery('a[href^="tel:"]')
      .css("cursor", "default")
      .on("click", function(e) {
        e.preventDefault();
      });
  }
});

if (window.matchMedia("(min-width: 769px)").matches) {
  /* ウィンドウサイズが 768px以上の場合に有効にしたい処理*/
  var _window = jQuery(window),
    _header = jQuery("#header"),
    heroBottom;

  _window.on("scroll", function() {
    heroBottom1 = jQuery(".mv").height();
    heroBottom2 = jQuery(".header-logo").height();
    heroBottom = heroBottom1 - heroBottom2;
    if (_window.scrollTop() > heroBottom) {
      _header.addClass("transform");
    } else {
      _header.removeClass("transform");
    }
  });

  var urlHash = location.hash;
  if (urlHash) {
    jQuery("body,html")
      .stop()
      .scrollTop(0);

    setTimeout(function() {
      let header = navHeight;
      var target = jQuery(urlHash);
      var position = target.offset().top;
      if (!jQuery("#a-gnav").hasClass("a-fixed")) {
        position = target.offset().top - header;
        console.log("ついてない");
      } else {
        console.log("ついてる");
      }
      //ランダムっぽいのでどちらにも対応
      jQuery("body,html")
        .stop()
        .animate({ scrollTop: position }, 300);
      console.log(header);
      console.log(position);
    }, 100);
  }
  _window.trigger("scroll");
}

jQuery('a[href^="#"]').click(function() {
  let id = jQuery(this).attr("href");
  let header = jQuery("#header").height();
  let speed = 300;
  let target = jQuery("#" == id ? "html" : id);
  let position = jQuery(target).offset().top - header;
  if ("fixed" !== jQuery("#header").css("position")) {
    position = jQuery(target).offset().top;
  }

  jQuery("html, body").animate(
    {
      scrollTop: position
    },
    speed
  );
  return false;
});
